@import 'color';

$margin: 20px;
$delete-height: 35px;

h1 {
  text-align: start;
}

.container {
  display: flex;
  align-items: center;
  color: $dark-main;
  
  .delete-icon {
    float: right;
    margin-left: auto;
    height: $delete-height;
    width: $delete-height;
    visibility: hidden;
    color: red;
  }
  .rank {
    margin-right: $margin;
  }
  .arrow-container {
    margin-right: $margin;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      cursor: pointer;
    }
  }
  &:hover {
    .delete-icon {
      visibility: visible;
    }
  }
  .item-title {
    width: 700px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.hide {
  visibility: hidden;
}