h1 {
  text-align: start;
}

.container {
  display: flex;
  align-items: center;
  color: rgb(63, 63, 63);
}
.container .delete-icon {
  float: right;
  margin-left: auto;
  height: 35px;
  width: 35px;
  visibility: hidden;
  color: red;
}
.container .rank {
  margin-right: 20px;
}
.container .arrow-container {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}
.container .arrow-container p {
  margin: 0;
  cursor: pointer;
}
.container:hover .delete-icon {
  visibility: visible;
}
.container .item-title {
  width: 700px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.hide {
  visibility: hidden;
}/*# sourceMappingURL=item.css.map */