$header-height: 80px;

.header {
  height: $header-height;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h1 {
    padding-left: 10px;
    color: rgb(63, 63, 63);
  }

  p {
    transform: translateY(6px) translateX(5px);
  }
}