.item-container {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: rgb(63, 63, 63);
}
.item-container ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.item-container ol li {
  border-bottom: 1px solid black;
}
.item-container .item-text {
  width: 800px;
  font-size: xx-large;
  height: 85px;
}
.item-container .clear-button {
  background-color: white;
  color: rgb(63, 63, 63);
  border-radius: 5px;
  height: 25px;
  font-size: large;
}/*# sourceMappingURL=list.css.map */