.header {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header h1 {
  padding-left: 10px;
  color: rgb(63, 63, 63);
}
.header p {
  transform: translateY(6px) translateX(5px);
}/*# sourceMappingURL=header.css.map */