@import 'color';

$list-width: 800px;

.item-container {

  width: $list-width;
  margin-left: auto;
  margin-right: auto;
  color: $dark-main;
  
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid black;
    }
  }

  .item-text {
    width: $list-width;
    font-size:xx-large;
    height: 85px;
  }
  .clear-button {
    background-color: white;
    color: $dark-main;
    border-radius: 5px;
    height: 25px;
    font-size: large;
  }
}